import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA SE------
    getSE() {
        ApiService.setHeader();
        return ApiService.get("v2/master/surat-edaran")
    },
    getAllSE(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/surat-edaran/all", { params: credentials })
    },
    getSESingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/surat-edaran/single", { params: credentials })
    },
    addSE(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/surat-edaran", credentials)
    },
    updateSE(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/surat-edaran", credentials)
    },
    deleteSE(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/master/surat-edaran", { data: credentials })
    },

    // -----API DATA SE AFKIR DINI------
    getSEAfkirDini() {
        ApiService.setHeader();
        return ApiService.get("v2/master/surat-edaran/afkir-dini")
    },
    getSEAfkirDiniSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/surat-edaran/afkir-dini/single", { params: credentials })
    },
    updateSEAfkirDini(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/surat-edaran/afkir-dini", credentials)
    },

    // -----API DATA SE CUTTING HE------
    getSECuttingHE() {
        ApiService.setHeader();
        return ApiService.get("v2/master/surat-edaran/cutting-he")
    },
    getSECuttingHESingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/surat-edaran/cutting-he/single", { params: credentials })
    },
    updateSECuttingHE(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/surat-edaran/cutting-he", credentials)
    },
}