<template>
  <div id="master-data-surat-edaran-add" data-app>
    
    <ValidationObserver ref="formAdd">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <!--begin::Form-->
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header py-3">
              <div class="card-title align-items-start flex-column">
				          <h3 class="card-label font-weight-bolder text-dark">Tambah Surat Edaran</h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1">Data surat edaran baru harus sudah fix dan ditanda tangani oleh Pejabat Penandatangan</span>
              </div>
          </div>
          <!--end::Header-->

          <div class="card-body">
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">No. SE</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Nomor Surat Edaran" rules="required|max:50" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-se-1" 
                              label-for="input-se-1"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                              autocomplete="off"
                              id="input-se-1"
                              v-model="form.no_se"
                              class="form-control form-control-solid"
                              required
                              size="lg"
                              placeholder="Nomor Surat Edaran"
                              :state="valid"
                              ></b-form-input>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Perihal</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Perihal" rules="required|min:5|max:200" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-se-2" 
                              label-for="input-se-2"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-textarea
                                id="input-se-2"
                                v-model="form.perihal"
                                class="form-control form-control-solid"
                                required
                                size="lg"
                                placeholder="Perihal Surat Edaran"
                                :invalid-feedback="errors[0]"
                                :state="valid"
                              ></b-form-textarea>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Tgl. SE</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Tanggal Surat Edaran" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-se-3" 
                              label-for="input-se-3"
                              :invalid-feedback="errors[0]"
                              description="pilih tanggal surat edaran sesuai yang ada di surat."
                              class="mb-0"
                              :state="valid">
                              <b-form-datepicker
                                id="input-se-3"
                                class="form-control form-control-solid pr-0"
                                v-model="form.tgl_se"
                                size="lg"
                                :value-as-date="true"
                                required
                                placeholder="Tanggal Surat Edaran"
                                :state="valid"
                              ></b-form-datepicker>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Data SE</label>
                  <div class="col-lg-9 col-xl-7">
                      <div>
                        <div class="checkbox-inline">
                            <label class="checkbox">
                                <input type="checkbox" v-model="form.cutting_he"/>
                                <span></span>
                                Cutting HE
                            </label>
                            <label class="checkbox">
                                <input type="checkbox" v-model="form.afkir_dini"/>
                                <span></span>
                                Afkir Dini
                            </label>
                        </div>
                        <span v-if="dataSEError" class="invalid-feedback d-block">The Data SE field is required</span>
                    </div>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">File SE</label>
                  <div class="col-lg-9 col-xl-7">
                    <a v-if="tempAttachments.length > 0" @click="removefiles" class="btn btn-light-primary font-weight-bold">Remove files</a>
                    <vue-dropzone
                      ref="myVueDropzone"
                      :include-styling="false"
                      :useCustomSlot="true"
                      id="dropzone"
                      @vdropzone-file-added="fileAdded"
                      @vdropzone-upload-progress="uploadProgress"
                      @vdropzone-success="uploadSuccess"
                      @vdropzone-error="uploadError"
                      :options="dropzoneOptions"
                    >
                      <a v-if="tempAttachments.length == 0" class="btn btn-light-primary font-weight-bold">Attach files</a>
                      <span v-else></span>
                    </vue-dropzone>
                    <span class="form-text text-muted">Ukuran file Maksimal 1MB (MegaByte) </span>

                    <div v-if="tempAttachments.length > 0" class="mt-3">  
                      <div v-for="(item, key) in tempAttachments" :key="key" class="alert alert-light" :class="{'cursor-pointer': !item.message}" @click="downloadPdf(item)">
                        <p> {{ item.title }} ({{ (item.size/1000000).toFixed(2) }} MB) </p>
                        <b-progress height="7px" :value="item.progress" :max="100" show-progress animated></b-progress>
                        <span v-if="item.message" class="form-text text-danger">{{ item.message }}</span>
                      </div>
                    </div>
                  </div>
              </div>
        
          </div>
      

          <div class="card-footer">
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-9">
                <button 
                  @click="onSubmit" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold"
                >
                  <i class="la la-send"></i>
                  Selanjutnya
                </button>
                &nbsp;
                <button
                  @click="onReset"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
    
      <!--end::Form-->
      </b-form>
    </ValidationObserver>

  </div>
</template>s

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SEService from "@/core/services/api/master/se.service";
import ErrorService from "@/core/services/error.service";
import JwtService from "@/core/services/jwt.service";

import vue2Dropzone from 'vue2-dropzone'

export default {
  name: "master-data-surat-edaran-add",
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      form: {
        no_se: null,
        perihal: null,
        tgl_se: null,
        file: null,
        afkir_dini: null,
        cutting_he: null
      },
      tempAttachments: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL }/v2/master/surat-edaran/file`,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`
        },
        maxFilesize: 1,
        maxFiles: 1,
        acceptedFiles: 'application/pdf',
        includeStyling: false,
        previewsContainer: false,
        parallelUploads: 20
      },
      show: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    dataSEError () {
        return !this.form.afkir_dini && !this.form.cutting_he
    },
    dataSESet () {
        if (this.form.afkir_dini) {
          return 'afkir-dini'
        }
        if (this.form.cutting_he) {
          return 'cutting-he'
        }
        return 'surat-edaran'
    },
  },
  methods: {
    removefiles () {
        this.$refs.myVueDropzone.removeAllFiles(true);
        this.form.file = null
        this.tempAttachments= [];
    },
    fileAdded(file) {      
      let attachment = {};
      attachment._id = file.upload.uuid;
      attachment.title = file.name;
      attachment.type = "file";
      attachment.extension = "." + file.type.split("/")[1];
      attachment.isLoading = true;
      attachment.progress = null;
      attachment.size = file.size;
      attachment.message = null;
      this.tempAttachments = [...this.tempAttachments, attachment];

    },
    uploadProgress(file, progress, bytesSent) {
      this.tempAttachments.map(attachment => {
        if (attachment._id === file.upload.uuid) {
          attachment.progress = `${Math.floor(progress)}`;
        }
      });
    },
    uploadSuccess(file, response) {
      this.form.file = response.data

      ErrorService.message(response)
    },
    uploadError(file, message, xhr) {
      this.tempAttachments.map(attachment => {
        if (attachment._id === file.upload.uuid) {
          attachment.message = message;
        }
      });
    },
    downloadPdf(file) {
      if (file.filename && file.progress == 100 && !file.message) {
        window.open(`${process.env.VUE_APP_API_URL}/dokumen/surat-edaran/thum/${file.filename}`, "_blank"); 
      }
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if (!this.form.afkir_dini && !this.form.cutting_he) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Harap memilih Data SE terlebih dahulu',
            })
            return;
        }

        if (!this.form.file) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Upload file SE untuk menyimpan data',
            })
            return;
        }
        
        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            SEService.addSE({ ...this.form, afkir_dini: !!this.form.afkir_dini, cutting_he: !!this.form.cutting_he })
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.$router.push({ name: 'master-data-surat-edaran-edit', params: { idSE: res.data.data._id }, query: { tab: this.dataSESet } })
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
        this.form.no_se= null
        this.form.perihal= null
        this.form.file = null
        this.form.afkir_dini= null
        this.form.cutting_he = null
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Surat Edaran" }]);
    if (!this.currentUser.menu.surat_edaran) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
  },
};
</script>